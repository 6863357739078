import Vue from "vue";
import { login, getUserInfo, getSteamInfo, getUserScore } from "@/api/index.js";
import { getServerInfo } from "@/api/index.js";
export default {
  namespaced: true,
  state: {
    user_status: false, //用户状态
    user_score: 0, //用户积分
    user_info: {}, //用户信息
    token: localStorage.getItem("AuroraShopToken"), //token
    steam_info: null, //steam用户信息
    server_info: {}, //服务器信息
    group_id: process.env.VUE_APP_GROUPID, //服务器组id
    server_list: [], //服务器列表
    server_id: localStorage.getItem("server_id") || "", //服务器id
    store_info: {}, //商城信息
  },
  mutations: {
    /* 设置用户信息 */
    setUserInfo(state, data) {
      state.user_info = data;
      state.user_status = true;
    },
    /* 设置steam信息 */
    setSteamInfo(state, data) {
      state.steam_info = data;
    },
    /* 设置token */
    setToken(state, token) {
      state.token = token;
      localStorage.setItem("AuroraShopToken", token);
    },
    /* 设置服务器id */
    setServerId(state, id) {
      state.server_id = id;
      localStorage.setItem("server_id", id);
      this.dispatch("user/getUserScore");
      this.dispatch("user/getServerInfo");
    },
    /* 设置服务器信息 */
    setServerInfo(state, name) {
      state.server_info = name;
    },
    /* 设置服务器列表 */
    setServerList(state, list) {
      state.store_info = list;
      state.server_list = list.servers;
    },
    /* 设置用户积分 */
    setUserScore(state, score) {
      state.user_score = score;
    },
    /* 退出登录 */
    logout(state, overdue) {
      localStorage.removeItem("AuroraShopToken");
      state.user_status = false;
      state.user_info = {}; //用户信息
      state.steam_info = null; //steam用户信息
      if (!overdue) {
        Vue.prototype.$message.success("退出成功");
      }
      state.userinfo_show = false;
    },
  },
  actions: {
    /* 登录 */
    login(ctx, form) {
      return new Promise((resolve) => {
        let basicAuth = "Basic " + window.btoa("shop:xhmily");
        const data = new FormData();
        data.append("username", form.username);
        data.append("password", form.password);
        login(
          { data },
          {
            headers: {
              "TENANT-ID": "1",
              AuthorizationType: "basic",
              Authorization: basicAuth,
            },
            params: { grant_type: "password" },
          }
        ).then((res) => {
          if (res.data.code === 1 && res.data.msg === null) {
            Vue.prototype.$message.error("帐号或密码错误");
            return;
          }
          ctx.commit("setToken", res.data.access_token);
          ctx.dispatch("getUserInfo").then((res) => {
            resolve(res);
          });
        });
      });
    },
    /* 验证码登录 */
    loginCode(ctx, form) {
      return new Promise((resolve) => {
        let basicAuth = "Basic " + window.btoa("app:app");
        const params = { mobile: "SMS@" + form.phone, code: form.msgcode, grant_type: "mobile" };
        login(
          { params },
          {
            headers: {
              "TENANT-ID": "1",
              AuthorizationType: "basic",
              Authorization: basicAuth,
            },
          }
        ).then((res) => {
          if (res.data.code === 1) return;
          ctx.commit("setToken", res.data.access_token);
          ctx.dispatch("getUserInfo").then((res) => {
            resolve(res);
          });
        });
      });
    },

    /* 获取用户信息 */
    getUserInfo(ctx) {
      return new Promise((resolve) => {
        getUserInfo().then((res) => {
          if (res.data.code === 1) return;
          const data = res.data.data.sysUser;
          ctx.commit("setUserInfo", data);
          Vue.prototype.$message.success("欢迎，" + data.name);
          ctx.dispatch("getSteamInfo");
          resolve();
        });
      });
    },
    /* 获取用户steam信息 */
    getSteamInfo(ctx) {
      if (!ctx.state.user_status) return;
      getSteamInfo().then((res) => {
        const data = res.data.data;
        if (data) {
          ctx.commit("setSteamInfo", data);
          ctx.dispatch("getUserScore");
        }
      });
    },
    /* 获取用户积分 */
    getUserScore(ctx) {
      return getUserScore(ctx.state.server_id).then((res) => {
        if (res?.data?.data) {
          ctx.commit("setUserScore", res.data.data.score);
        }
      });
    },
    /* 获取服务器信息 */
    getServerInfo(ctx) {
      return getServerInfo(ctx.state.server_id).then((res) => {
        ctx.commit("setServerInfo", res.data.data);
      });
    },
  },
};
