import { getReq, postReq, postParamsReq } from "./network/index.js";

/* 注册 */
export const register = (params, other) => {
  return postReq("/admin/user/reg", params, other);
};

/* 登录 */
export const login = (arg, other) => {
  return postParamsReq("/auth/oauth/token", arg, other);
};

/* 发送验证码 */
export const sendCode = (phone) => {
  return getReq("/admin/mobile/" + phone);
};

/* 获取当前登陆用户的信息 */
export const getUserInfo = () => {
  return getReq("/admin/user/info");
};

/* 获取当前登陆用户的steam信息 */
export const getSteamInfo = () => {
  return getReq("/mapz/player/steamInfo");
};

/* 绑定Steam帐号 */
export const bindSteam = (code) => {
  return getReq("/mapz/player/applyPlayerCode", { code });
};

/* 获取服务器组 */
export const getGroup = () => {
  return getReq("/mapz/servergroup/getGroup?groupId=" + process.env.VUE_APP_GROUPID);
};

/* 获取服务器信息 */
export const getServerInfo = (id) => {
  return getReq("/mapz/serverinfo/get?id=" + id);
};

/* 获取积分信息 */
export const getUserScore = (id) => {
  return getReq("/mapz/playerscore/myScore?serverId=" + id);
};

/* 获取店铺配置 */
export const getShopConfig = (id) => {
  return getReq("/mapz/servershopconfig/getShopConfigByGroup?groupId=" + id);
};

/* 获取公告信息 */
export const getNotice = (id) => {
  return getReq("/mapz/servershopnotice/listNoticeByGroup?groupId=" + id);
};
