<template>
  <div class="App">
    <router-view></router-view>

    <!-- 侧边栏弹出蒙版 -->
    <transition name="fade">
      <div class="mask1" v-if="state_sidebar_show" @click="StoreSetSidebarShow(false)"></div>
    </transition>

    <!-- 用户信息弹出蒙版 -->
    <transition name="fade">
      <div class="mask2" v-if="state_userinfo_show" @click="StoreSetUserinfoShow(false)"></div>
    </transition>
  </div>
</template>
<script>
import { mapState, mapMutations, mapActions } from "vuex";
import { getGroup } from "@/api/index.js";
export default {
  name: "App",
  data() {
    return {};
  },
  computed: {
    ...mapState("user", {
      state_group_id: "group_id", //服务器id
      state_server_id: "server_id", //服务器id
    }),
    ...mapState("user", {
      state_token: "token", //token
    }),
    ...mapState("global", {
      state_sidebar_show: "sidebar_show",
      state_userinfo_show: "userinfo_show",
    }),
  },
  created() {
    /* 判断本地是否存在token */
    if (this.state_token) {
      this.StoreGetUserInfo();
    }
    /* 判读本地是否存在服务器id */
    if (this.state_server_id) {
      this.StoreGetServerInfo();
    }
    this.StoreSetScreenWidth(window.innerWidth);
    window.addEventListener("resize", () => {
      this.StoreSetScreenWidth(window.innerWidth);
      this.$bus.$emit("resize"); //全局监听窗口
    });

    getGroup(this.state_group_id).then((res) => {
      if (res.data.code === 1) {
        this.$message.error(res.data.msg);
      } else {
        this.StoreSetServerList(res.data.data);
      }
    });
  },
  methods: {
    ...mapMutations("user", {
      StoreSetServerId: "setServerId", //设置服务器id
      StoreSetServerList: "setServerList", //设置服务器列表
    }),
    ...mapActions("user", {
      StoreGetUserInfo: "getUserInfo",
      StoreGetServerInfo: "getServerInfo",
    }),
    ...mapMutations("global", {
      StoreSetScreenWidth: "setScreenWidth", //设置设备宽度
      StoreSetSidebarShow: "setSidebarShow", //设置显示/隐藏侧边栏
      StoreSetUserinfoShow: "setUserinfoShow", //设置显示/隐藏用户信息
    }),
  },
};
</script>
<style scoped lang="less">
.App {
  position: relative;
  width: 100vw;
  min-width: 360px;
  height: 100vh;

  .mask1,
  .mask2 {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 2;
  }

  .mask1 {
    z-index: 3;
  }
}
</style>
